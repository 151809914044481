import useSWR from "swr";
import { fetcher } from "./fetcher";

const useMainStockList = ({ loc, isDetail = false }: { loc: string | undefined, isDetail?: boolean }): [any, any, boolean] => {
    const { data: explodeDt, isValidating } = useSWR(
        `/api/mainstock?detail=${isDetail}&loc=${loc === undefined ? "" : loc}`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !explodeDt && isValidating;

    const { stock, index } = explodeDt ? explodeDt : { stock: [], index: [] };
    const stockData = explodeDt ? [].concat(...stock) : [];
    const indexData = explodeDt ? [].concat(...index) : [];
    return [stockData, indexData, isValid];
}

export const useMainIndexList = (): [any, boolean] => {
    const { data: explodeDt, isValidating } = useSWR(
        `/api/mainIndex`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !explodeDt && isValidating;

    const { index } = explodeDt ? explodeDt : { index: [] };
    const indexData = explodeDt ? [].concat(...index) : [];
    return [indexData, isValid];
}

export default useMainStockList;