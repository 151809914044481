import { TYPO } from "styles/Typo";
import DividerLine from "./line";

function DividerButton({ children, ...props }: any) {
  return (
    <div className={`w-full  flex flex-col  ${props.className}`} {...props}>
      <DividerLine size="sm" />
      <div
        className={`cursor-pointer px-[18px] py-3.5 w-full text-center flex justify-center items-center gap-1`}
      >
        <span className={`${TYPO.TYPO6_MD} text-gray60`}>{props.text}</span>
        {props?.icon && props.icon}
      </div>
      <div className={`w-full h-4 bg-gray2 `}></div>
    </div>
  );
}

export default DividerButton;
