import SkeletonBlock, { SkeletonBlock2 } from ".";

export const StockListSkeleton = () => {
  return (
    <div className="w-full flex flex-col bg-white pb-4">
      <div className={`pt-[30px] pl-6`}>
        <SkeletonBlock width={70} height={30} />
      </div>
      <div className="px-6 py-4 flex items-center gap-4 ">
        <SkeletonBlock width={125} height={46} />
        <SkeletonBlock width={125} height={46} />
      </div>
      <div className="flex flex-col gap-2 py-2">
        <div className="w-full flex justify-end px-6">
          <SkeletonBlock width={80} height={16} />
        </div>
        <div className="px-6 gap-2 flex flex-col ">
          <SkeletonBlock width={"100%"} height={66} />
          <SkeletonBlock width={"100%"} height={66} />
          <SkeletonBlock width={"100%"} height={66} />
        </div>
      </div>
    </div>
  );
};

export const StrategySkeletonDesktop = () => {
  return (
    <div className="w-full">
      <div className="pt-[30px] px-6">
        <SkeletonBlock2 width={200} height={25} />
      </div>
      <div className="py-6 px-5 flex items-center gap-4">
        <SkeletonBlock2 width={295} height={374} />
        <SkeletonBlock2 width={295} height={374} />
      </div>
    </div>
  );
};

export const StrategySkeletonMobile = () => {
  return (
    <div className="w-full">
      <div className="pt-[30px] px-6">
        <SkeletonBlock2 width={200} height={25} />
      </div>
      <div className="py-6 px-5 flex items-center gap-4">
        <SkeletonBlock2 width={295} height={374} />
      </div>
    </div>
  );
};

export const MainDiscussSkeleton = () => {
  return (
    <div className="flex flex-col bg-white gap-4 pb-4">
      <div className="px-6 pt-6">
        <SkeletonBlock width={60} height={26} radius={8} />
      </div>
      <div className="flex flex-col gap-3 py-2 px-5">
        <SkeletonBlock width={"100%"} height={164} />
      </div>
    </div>
  );
};

export const IndexSkeleton = () => {
  return (
    <div className="px-6 py-4 flex gap-5">
      <SkeletonBlock width={150} height={46} />
      <SkeletonBlock width={150} height={46} />
    </div>
  );
};

export const HighScoreStockSkeleton = () => {
  return (
    <div className="bg-white">
      <div className="px-6 pt-6">
        <SkeletonBlock width={170} height={29} />
      </div>
      <div className="py-2 flex flex-col gap-4 px-6">
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
      </div>
      <div className="flex items-center justify-center">
        <SkeletonBlock width={100} height={26} />
      </div>
    </div>
  );
};
export const HighScoreStockDetailSkeleton = () => {
  return (
    <div className="bg-white">
      <div className="px-6 pt-6">
        <SkeletonBlock width={170} height={29} />
      </div>
      <div className="py-5 flex gap-2 px-6">
        <div className="grow">
          <SkeletonBlock width={"100%"} height={40} />
        </div>
        <div className="grow">
          <SkeletonBlock width={"100%"} height={40} />
        </div>
      </div>
      <div className="py-2 flex flex-col gap-4 px-6">
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
      </div>
    </div>
  );
};

export const StrategySkeleton = () => {
  return (
    <div className="bg-white">
      <div className="flex flex-col w-full gap-2">
        <div className="px-6 pt-6">
          <SkeletonBlock width={170} height={29} />
        </div>
        <div className="py-2 flex flex-col ">
          <div className="px-6 py-2 flex items-center gap-3">
            <SkeletonBlock width={120} height={44} />
            <SkeletonBlock width={44} height={44} />
            <SkeletonBlock width={44} height={44} />
          </div>
          <div className="py-2 flex flex-col gap-2 px-6">
            <SkeletonBlock width={"100%"} height={30} />
            <SkeletonBlock width={"100%"} height={30} />
            <SkeletonBlock width={"100%"} height={30} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <SkeletonBlock width={100} height={26} />
      </div>
    </div>
  );
};

export const TrendIndustrySkeleton = () => {
  return (
    <div className="bg-white pb-4">
      <div className="px-6 pt-6">
        <SkeletonBlock width={170} height={29} />
      </div>
      <div className="py-2 flex flex-col gap-4 px-6">
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
        <SkeletonBlock width={"100%"} height={40} />
      </div>
      <div className="flex items-center justify-center">
        <SkeletonBlock width={100} height={26} />
      </div>
    </div>
  );
};

export const HotTopicDetailSkeleton = () => {
  return (
    <div className="bg-white">
      <div className="px-6 pt-6">
        <SkeletonBlock width={170} height={29} />
      </div>

      <div className="py-2 flex flex-col gap-5 px-6">
        <SkeletonBlock width={"100%"} height={45} />
        <SkeletonBlock width={"100%"} height={45} />
        <SkeletonBlock width={"100%"} height={45} />
        <SkeletonBlock width={"100%"} height={45} />
        <SkeletonBlock width={"100%"} height={45} />
      </div>
    </div>
  );
};
